@import "../../variables.scss";

#explore-studies-page {
  .explore-content-wrapper {
    margin-top: 24px;

    .ant-input,
    .ant-input-number {
      width: 100%;
    }

    .explore-info-wrapper {
      width: 100%;

      .explore-info-content {
        display: flex;
        flex-direction: column;
        gap: 14px;

        .title {
          color: #01625f;
          font-family: "RocGrotesk";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 38px;
        }

        .description {
          font-family: "TabletGothic";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .map-filter-container {
      .map-card-wrapper,
      .filter-card-wrapper {
        min-height: 505px;
      }

      .map-card-wrapper {
        border-radius: 20px;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 4px 8px 0px rgba(16, 24, 40, 0.25);

        .ant-card-body {
          padding: 20px 20px 20px 0;
          display: flex;
          justify-content: center;
        }
      }

      .filter-card-wrapper {
        border-radius: 20px;
        background: #d0e2e2;

        .ant-card-body {
          padding: 12px 20px;
        }
      }
    }

    .explore-filter-wrapper {
      .ant-select,
      .ant-input {
        width: 100%;
      }

      .filter-label {
        font-family: "TabletGothic";
        margin-bottom: 4px;
      }

      .ant-space {
        width: fit-content;
      }

      .search-button,
      .clear-button {
        font-family: "TabletGothic";
        border-radius: 40px;
        border: 1px solid #01625f;
        font-weight: 400;
      }

      .search-button {
        background: #01625f;
        color: #fff;
      }

      .clear-button {
        color: #01625f;
        background: transparent;
      }
    }

    .table-wrapper {
      .ant-table-thead {
        tr {
          th.ant-table-cell,
          td.ant-table-row-expand-icon-cell {
            background: #d1d1d1;
            color: #000;
          }
        }
      }

      .ant-table-thead > tr > th::before {
        background: none;
      }
    }
  }
}

.reference-form-container {
  .ant-input,
  .ant-input-number {
    width: 100%;
  }
}
