.container {
  span {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 100px;
    cursor: pointer;
    &.environment {
      color: #01625f;
      background-color: #f0fcf5;
    }
    &.income {
      color: #0098ff;
      background-color: #eaf7ff;
    }
  }
}
