@import "../../../variables.scss";

.assessment-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 4rem 3rem 4rem;
  gap: 24px;

  .assessment-form {
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 32px 0 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    .ant-btn-primary {
      height: auto;
      padding: 8px 14px;
      align-items: center;
      border-radius: 40px;
      box-shadow: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
    .assessment-form-header,
    .assessment-form-body {
      width: 100%;
      max-width: 960px;
    }
    .assessment-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      list-style: none;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
      li {
        display: inline-block;
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        &.active {
          border: 1px solid #d5d7da;
          box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);
          background-color: #fff;
          a {
            color: $primary-color;
          }
        }
        a {
          color: #777;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 157.15%; /* 22.001px */
        }
      }
    }
    .assessment-form-header {
      color: $primary-color;
      h1 {
        color: $primary-color;
        font-size: 32px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 131.25% */
        margin-block-start: 0;
        margin-block-end: 12px;
      }
      p {
        width: 100%;
        max-width: 556px;
        color: $primary-color;
        font-family: "Tablet Gothic";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 157.15%; /* 25.144px */
      }
    }
    .assessment-form-body {
      padding-top: 10px;
      .ant-radio-wrapper {
        border-radius: 20px;
        border: 1px solid #f2f2f2;
        background: #fff;
        box-shadow: 0px 4px 10.3px 0px rgba(0, 0, 0, 0.12);
        padding: 8px 18px;
      }

      .ant-radio-wrapper span.ant-radio + * {
        width: 100% !important;
        padding-inline-start: 12px;
        padding-inline-end: 0;
        .question-option {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }

      .ant-form-vertical .ant-form-item-label {
        padding: 0 0 12px;
        margin: 0;
      }
      .assessment-container
        .assessment-form
        .assessment-form-body
        .ant-form-item
        .ant-form-item-label,
      .ant-form-item .ant-form-item-label,
      .question-option {
        color: #000;

        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 187.5% */
      }
      .ant-form-item .ant-form-item-label span {
        font-size: 16px;
      }
      .question-option {
        font-size: 14px;
        .question-option-icon {
          color: #a4a4a4;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  .assessment-practices {
    width: 100%;
    max-width: 640px;
    min-height: calc(100vh - 148px);
    position: sticky;
    top: 0;
    margin: 32px 0 32px;
    border-radius: 20px;
    border: 1px solid #f2f2f2;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 18px;
    .assessment-practices-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 13px;
      margin-bottom: 22px;
      h2 {
        color: $primary-color;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 157.15%; /* 31.43px */
        margin-block-start: 0;
        margin-block-end: 0;
        color: var(--green-100, #01625f);
      }
      .ant-tag {
        border-radius: 3px;
        background-color: #f5f5f5;
        color: #414651;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 157.15%; /* 18.858px */
      }
    }
    .assessment-practices-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      height: 100%;
      padding-top: 92px;
      h3 {
        width: 100%;
        max-width: 347px;
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 157.15%; /* 28.287px */
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
    .assessment-practices-list {
      width: 100%;
      height: calc(100vh - 264px);
      list-style: none;
      padding-inline-start: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 36px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow-y: auto;
      li.assessment-practice {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: space-between;
        gap: 8px;
        border-radius: 16px;
        border: 1px solid #e9e9e9;
        background: #fff;
        padding: 8px 12px;
        .assessment-practice-content {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          a {
            display: inline-block;
            width: 58px;
            color: $primary-color;
            font-family: "TabletGothic";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 200% */
          }
          div[role="button"] strong {
            cursor: pointer;
          }
        }
      }
    }
    .assessment-footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;

      .ant-btn {
        padding-right: 0;
        color: $primary-color;
        font-weight: 700;
        .ant-space-item svg {
          margin-top: 4px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .assessment-container {
    flex-direction: column;

    .assessment-pratices {
      position: relative;
    }
  }
}
