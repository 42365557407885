@import "../../../variables.scss";

.intervention-library-container {
  padding: 12px 32px;
  @media (max-width: 1280px) {
    padding: 6px 32px;
  }
  .intervention-library-header ul {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 8px;
    li {
      display: inline-block;
      color: #8a8a8a;
      font-family: "TabletGothic";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 166.667% */
      display: inline-flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      width: auto;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 6px;
      }
      &.active {
        color: $primary-color;
        background-color: #ddf8e9;
      }
    }
  }
  .intervention-library-content {
    .intervention-library-content-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 12px;
      padding: 24px 18px;
      border-radius: 20px;
      background-color: #abefc6;
      background-image: url("/assets/images/procurement-library/bg-card-procurement-green.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: contain;
      h1 {
        margin-block-start: 0;
        margin-block-end: 0;
        color: $primary-color;
        font-size: 34px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 123.529% */
        letter-spacing: -0.68px;
      }
      .ant-form {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        gap: 12px;
        @media (max-width: 820px) {
          grid-template-columns: repeat(2, 1fr);
        }
        .intervention-library-search-button {
          width: fit-content;
          height: auto;
          display: flex;
          padding: 0 14px;
          margin-top: 6px;
          justify-content: space-between;
          align-items: center;
          flex-shrink: 0;
          border-radius: 40px;
          border: 1px solid $primary-color;
          background: $primary-color;
          color: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          .ant-space-item span {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }
          .ant-space-item svg {
            margin-top: 4px;
          }
        }
      }
    }
    .intervention-library-content-body {
      width: 100%;
      max-width: 1440px;
      height: calc(100vh - 360px);
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 16px;
      margin: 24px auto;
      .ant-list {
        margin-bottom: 96px;
        .intervention-card {
          width: 100%;
          min-height: 164px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          gap: 12px;
          padding: 12px;
          border-radius: 16px;
          border: 1px solid #e9e9e9;
          background-color: #fff;
          .intervention-card-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 6px;
            .intervention-card-categories {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 4px;
              .ant-tag {
                padding-inline: 0;
                padding: 1px 6px;
                border-radius: 999px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
              }
            }
          }
          .intervention-card-description {
            width: 100%;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            cursor: pointer;
            strong {
              margin-block-start: 0;
            }
          }
          .intervention-card-footer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            a {
              padding-left: 0;
              padding-right: 0;
              color: $primary-color;
              .ant-space-item svg {
                margin-top: 4px;
              }
            }
          }
        }
      }
    }
    .intervention-library-content-footer {
      text-align: center;
    }
  }
}
