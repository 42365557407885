@import "../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Actor&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

#root {
  h1 {
    font-size: 48px !important;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  h2 {
    font-size: 38px !important;
  }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 36px !important;
    }
  }
}

.procurement-library-container {
  width: 100%;
  min-height: calc(100vh - 80px);
  overflow: hidden;
  .jumbotron {
    width: 100%;
    height: auto;
    background-image: linear-gradient(
        135deg,
        rgba(28, 62, 32, 0.64) 30.97%,
        rgba(127, 127, 84, 0) 65%
      ),
      url("/assets/images/procurement-library/jumbotron.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .jumbotron-content {
      position: relative;
      width: 100%;
      min-height: 32rem;
      color: white;
      text-align: left;
      z-index: 10;
      padding: 3rem 4rem;
      display: flex;
      flex-direction: column;
      gap: 62px;
      justify-content: space-between;

      @media (max-width: 820px) {
        min-height: 1024px;
      }

      .jumbotron-text {
        width: 687px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;

        h1,
        .caption {
          width: 735px;
        }
        h1 {
          font-style: normal;
          font-weight: 700;
          line-height: 72px; /* 112.5% */
        }
        .caption {
          width: 90%;
          font-family: "RocGrotesk";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 157.15%; /* 25.144px */
          margin-block-start: 0;
          margin-block-end: 0;
        }

        @media screen and (max-width: 1024px) {
          h1,
          .caption {
            width: 600px;
          }
        }
      }

      .ant-btn {
        height: auto;
        border-radius: 40px;
        border: 1px solid $primary-color;
        background: $primary-color;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .ant-space-item span {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
        }
        .ant-space-item svg {
          margin-top: 4px;
        }
      }

      .jumbotron-cards {
        position: absolute;
        bottom: -112px;
        left: 0;
        width: 100%;
        padding: 94px 62px 0;

        .ant-list {
          .ant-card {
            min-height: 200px;
            border-radius: 20px;
            border: 1px solid #ddd;
            .ant-card-body {
              padding: 10px 20px 16px;
              .card-content {
                width: 100%;
                min-height: 180px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .card-icon {
                  position: relative;
                  width: 60px;
                  height: 60px;
                  .icon-back {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-size: cover;
                    background-position: center;
                  }
                  .icon-front {
                    color: $primary-color;
                    position: absolute;
                    top: 12px;
                    left: 25%;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    &.front-0 {
                      top: 18px;
                      left: 14%;
                    }
                  }
                }
                .card-title {
                  h3 {
                    color: $primary-color;
                    font-size: 22px !important;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px !important; /* 133.333% */
                    letter-spacing: -0.48px !important;
                    margin-block-start: 8px;
                    margin-block-end: 4px;
                  }
                  @media screen and (max-width: 1024px) {
                    h3 {
                      font-size: 20px !important;
                      line-height: 30px !important;
                    }
                  }
                }
                .card-cta {
                  text-align: right;
                }
              }
            }
          }
          .ant-row {
            div:nth-child(1) {
              .ant-card {
                background: #9cd7ff;
              }
            }
            div:nth-child(2) {
              .ant-card {
                background: #7dd3ab;
              }
            }
            div:nth-child(3) {
              .ant-card {
                background: #eed06e;
              }
            }
          }
        }
      }
    }
  }

  .key-features-container {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    .key-features-content {
      width: 100%;
      padding: 10rem 4rem 3rem;

      h2,
      h3 {
        margin-block-start: 0;
      }
      h2 {
        margin-block-end: 32px;
        color: $primary-color;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 131.579% */
      }
      .key-features {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 48px 32px;
        @media (max-width: 820px) {
          grid-template-columns: 1;
        }
        .key-feature {
          width: 100%;
          h3 {
            margin-top: 16px;
            margin-block-end: 8px;
            color: $primary-color;
          }
          .key-feature-description {
            color: #000;
            font-family: "Actor", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 157.15%; /* 28.287px */
          }
          .key-icon {
            position: relative;
            width: 48px;
            height: 48px;
            .key-icon-back {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 0;
              background-size: cover;
              background-position: center;
              svg path {
                fill: $primary-color;
              }
            }
            .key-icon-front {
              color: $primary-color;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
              &.front-0 {
                top: 18px;
                left: 14%;
              }
            }
          }
        }
      }
    }
  }

  #footer-disclaimer h2 {
    margin-block-end: 16px;
  }
}
