@import "../../variables.scss";

#cii-page,
.cii-page-container {
  h2 {
    color: $primary-color;
    font-family: "RocGrotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
  }

  h3 {
    color: $primary-color;
    font-family: "RocGrotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 10px 0;
  }

  p {
    margin: 0;
    font-family: "TabletGothic";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .jumbotron-wrapper {
    background-image: url("/assets/images/cii/cii-jumbotron.png") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center -11rem;
    width: 100%;
    height: 26rem;
    position: relative;
    overflow: hidden;

    .jumbotron-gradient-wrapper {
      position: relative;
      display: flex;

      .jumbotron-gradient-overlay {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 26rem;
        background: linear-gradient(
          90deg,
          rgba(28, 62, 32, 0.67) 0%,
          rgba(127, 127, 84, 0) 100%
        );
        z-index: 0;
      }

      .jumbotron-text-wrapper {
        z-index: 1;
        padding: 3rem $default-padding-size;
        h1 {
          font-family: "RocGrotesk" !important;
          color: #ffffff;
          font-weight: 900;
          font-size: 48px !important;
          line-height: 72px; /* 112.5% */
          margin: 0;
        }

        h3 {
          color: #ffffff;
          font-weight: 400;
          font-size: 18px;
          line-height: 157.15%; /* 25.144px */
          width: 747px;
          margin-bottom: 24px;
          margin-block-end: 40px;
        }
      }
    }
  }

  .about-wrapper {
    background-color: #fff;
    padding: 2rem $default-padding-size 3rem $default-padding-size;

    .logos-wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 20px;
      border-bottom: 2px solid #f2f2f2;
      padding-bottom: 20px;

      div {
        flex: 1 1 33%;
        text-align: center;

        &:nth-child(2) {
          border-left: 2px solid #f2f2f2;
          border-right: 2px solid #f2f2f2;
        }
      }
    }

    .about-text-wrapper {
      .about-description-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Two equal columns
        gap: 20px;

        @media (max-width: 768px) {
          grid-template-columns: 1fr; // Single column for small screens
        }

        p {
          color: #8a8a8a;
        }
      }
    }
  }

  .indicators-wrapper {
    padding: 2rem 0 4rem $default-padding-size;
    background-color: #eaf2f2;

    h2 {
      text-align: center;
    }

    .indicator-carousel-wrapper {
      position: relative;
      margin-left: 100px;

      .indicator-item {
        min-width: 170px !important;
        min-height: 170px;
        border-radius: 20px;
        border: 1px solid #f2f2f2;
        background: #fffaeb !important;

        margin-right: 10px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        p {
          margin-top: 20px;
          font-size: 14px;
        }
      }

      .slick-slide > div {
        display: flex;
        justify-content: center;
      }

      /* Adjust dots */
      .slick-dots {
        bottom: -20px;

        li > button {
          height: 10px;
          width: 10px;
          background-color: #d6d3d3;
          border-radius: 50%;
        }
        li.slick-active > button {
          background-color: $primary-color;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 16px; /* Adjust icon size */
      color: white; /* Arrow color */
      background: $primary-color; /* Circle background */
      width: 30px; /* Circle size */
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%; /* Makes it circular */
      cursor: pointer;
      padding-top: 7px;
      font-weight: 700;
    }

    .slick-prev {
      left: -18px !important;
    }
    .slick-next {
      right: 24px !important;
    }
  }

  .pch-wrapper {
    padding: 2rem $default-padding-size 2rem $default-padding-size;
    background-color: #fcf9f4;
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    flex-wrap: wrap;

    .pch-item {
      flex: 1 1 calc(33.33% - 20px);

      // FOR 1 row 2 col, 2 roe 1 col
      // &:nth-child(1),
      // &:nth-child(2) {
      //   flex: 1 1 calc(50% - 20px); /* 2 items in first row (50% width each) */
      // }

      // &:nth-child(3) {
      //   flex: 1 1 100%; /* Full-width for the second row */
      // }
      // EOL

      a {
        color: #000;
        text-decoration: underline;
      }

      .ant-btn-link {
        color: #000;
      }
    }
  }

  .contributing-wrapper {
    padding: 2rem $default-padding-size 3rem $default-padding-size;

    .contributing-content-wrapper {
      padding-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 equal columns */
      grid-template-rows: repeat(
        3,
        auto
      ); /* 3 rows, height adjusts based on content */
      gap: 20px; /* Space between grid items */

      div.contributing-item {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
      }
    }
  }

  .value-org-wrapper {
    padding: 2rem $default-padding-size 3rem $default-padding-size;
    background-color: #fcf9f4;

    .value-org-content-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr); // Two equal columns
      place-items: center;
      gap: 30px;

      @media (max-width: 1050px) {
        grid-template-columns: 1fr; // Single column for small screens
      }

      .value-org-item-wrapper {
        flex: 1 1/2;
        display: flex;
        gap: 20px;
        flex-direction: column;

        .value-org-item {
          display: flex;
          gap: 12px;
          justify-content: flex-start;

          h3 {
            margin: 0;
            font-size: 16px;
          }
        }
      }

      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

        .ant-image-img {
          border-radius: 12px;
          max-width: 650px !important;
        }
      }
    }
  }

  .contribute-to-wrapper {
    padding: 2rem $default-padding-size 3rem $default-padding-size;
    background-color: #fff;

    h2,
    p {
      text-align: center;
      margin: 14px;
    }

    .contact-card-wrapper {
      padding: 1.5rem 0;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      .contact-card-item {
        min-width: 22%;
        max-width: 275px;
        border-radius: 12px;
        background: #eaf2f2;
        padding: 24px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        .name,
        .email > a {
          color: $primary-color;
        }

        .name {
          margin-top: 10px;
          font-family: "RocGrotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }

        .org {
          color: #000;
          font-family: "TabletGothic";
          font-size: 13px;
          font-style: normal;
          font-weight: 500;

          &.hidden {
            color: #eaf2f2;
          }
        }

        .email {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .ack-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        max-width: 80%;
        color: #000;
        font-size: 14px;
        line-height: 30px;

        a {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}
