$primary-color: #01625f;
$secondary-color: #bcd9d8;
$muted-color: #b3b3b3;
$bg-color-grey: #f0f2f5;
$bg-color-white: #ffffff;
$yellow-color: #ffc505;
$light-green: #47d985;

$default-space-size: 1.5rem;
$default-ant-card-body-padding: 0.9rem 1.5rem;
$default-padding-size: 4rem;
$default-breadcrumb-padding-size: 2.2rem;

$card-head-min-height: 42px;

@font-face {
  font-family: "TabletGothic";
  src: url("/assets/fonts/TabletGothic.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TabletGothicBold";
  src: url("/assets/fonts/TabletGothic-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "RocGrotesk";
  src: url("/assets/fonts/RocGrotesk.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RocGroteskBold";
  src: url("/assets/fonts/RocGrotesk-bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
