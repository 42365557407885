@import "../../../variables.scss";

#case-detail {
  .sider-collapsed-button {
    background: "transparent";
    margin-bottom: 10px;
    border: "none";
    border-radius: 50%;
    border-width: 0;

    &:hover {
      background-color: rgba(165, 236, 195, 0.3);
    }
  }

  .case-sidebar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e1e0da;
    background: #fff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    padding: 12px 15px 12px 20px;
    z-index: 2;

    .case-step-container {
      min-height: 100vh;
      padding-bottom: 75px;

      .case-step-wrapper {
        .ant-steps-item {
          // not active
          .ant-steps-item-tail::after {
            border: 1px solid #e1e0da;
          }

          .ant-steps-item-icon {
            border: 1px solid #e3e3e3;

            .ant-steps-icon {
              color: #979797;
              text-align: center;
              font-family: "RocGrotesk";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              // line-height: 24px;
            }
          }

          .ant-steps-item-title {
            font-family: "RocGrotesk";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            padding: 0;
          }
          .ant-steps-item-description {
            color: #475467;
            font-family: "TabletGothic";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            margin-top: 5px;
          }

          // when active
          &.ant-steps-item-active {
            .ant-steps-item-tail::after {
              border: 1px solid $primary-color;
            }

            .ant-steps-item-icon {
              border: 1px solid $primary-color;
              box-shadow: 0 0 2px 4px rgba(203, 244, 220, 1);
              background: #eaf2f2;

              .ant-steps-icon {
                color: $primary-color;
              }
            }
          }

          // when finished
          &.ant-steps-item-finish {
            .ant-steps-item-icon {
              border: 1px solid #48d985;
              background: #48d985;

              .ant-steps-icon {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .case-content-container {
    // rewrite content layout css for this page
    .ant-breadcrumb {
      padding: 0;
    }

    .content-wrapper,
    .title-wrapper {
      padding: 10px 18px !important;
    }

    // override content-layout css
    .content-card-container {
      margin-left: 10px;

      .content-card-wrapper {
        padding: 0 1.5rem;
      }
    }
  }

  .case-button-wrapper {
    background: #fff;
    border-top: 1px solid #e1e0da;
    padding: 14px 24px;
    // position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
  }
}

@media (max-height: 719px) {
  .case-sidebar-container {
    overflow: auto;
    height: 95vh;
  }
}

/* 🔹 Tall Screens (1000px height and above) */
@media (min-height: 1000px) {
  .case-sidebar-container {
    padding: 20px !important;

    .case-step-container {
      .case-step-wrapper {
        .ant-steps-item {
          padding-bottom: 20px;
        }
      }
    }
  }
}
