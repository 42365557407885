@import "../../../variables.scss";

.step-segment-tabs-container {
  &.ant-tabs {
    .ant-tabs-nav {
      margin: 0;

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            border-radius: 15px 15px 0 0 !important;
            background: #e1e0e0;
            border-left: 1px solid #e1e0e0;
            border-top: 1px solid #e1e0e0;
            border-right: 1px solid #e1e0e0;

            &.ant-tabs-tab-active {
              background: #fff;
            }
          }
        }
      }
    }

    .ant-tabs-content {
      background: #fff;
      padding: 10px 14px;
      border-radius: 0 20px 20px 20px;
      margin-bottom: 100px;
      border-left: 1px solid #e1e0e0;
      border-bottom: 1px solid #e1e0e0;
      border-right: 1px solid #e1e0e0;
    }
  }

  // anticipate over line in tabs nav
  .ant-tabs-top > .ant-tabs-nav::before {
    margin-right: 16px;
  }
}

.card-income-target-wrapper {
  font-family: "TabletGothic";
  background: $primary-color;
  color: #fff;
  border-radius: 20px;

  .ant-card-body {
    padding: 20px;

    .income-target-value {
      font-family: "RocGrotesk";
      font-weight: bold;
      font-size: 22px;
    }

    .income-target-text {
      max-width: 225px;
    }
  }
}

.card-lib-wrapper {
  background: #f2f2f2;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  .ant-card-body {
    padding: 20px;

    .lib-text,
    .lib-source {
      font-family: "TabletGothic" !important;
    }

    .lib-text {
      font-size: 16px;
      font-weight: 700;
    }

    .lib-source {
      font-size: 14px;
      font-weight: 400;

      a {
        color: #000;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
      }
    }
  }
}

#enter-income-data {
  .total-income-container {
    padding: 0 10px;

    .total-income-title-wrapper {
      font-family: "RocGrotesk";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      color: #000;
    }

    .total-income-value-wrapper {
      font-family: "TabletGothic";
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      .level-text {
        font-size: 12px;
      }

      .value-text {
        font-family: "RocGrotesk";
        font-size: 14px;
      }
    }
  }

  .percentage-tag-wrapper {
    .ant-tag {
      border-radius: 16px;
      font-size: 11px;
      font-family: "TabletGothic";
      line-height: 18px;
      font-weight: normal;
      padding: 2px 7px 0px 7px;
      min-width: 55px;
      text-align: right;
      span {
        margin-top: 4px;
      }
    }
  }

  .income-questions-wrapper {
    padding: 10px 0;

    .ant-card {
      .ant-card-head {
        padding: 10px;
        border-radius: 20px 20px 0px 0px;
        border: 1px solid #e5e5e5;
        background: #f2f2f2;
        min-height: 0 !important;

        .title-text {
          font-family: "RocGrotesk";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 157.15%;
        }

        .value-text {
          font-family: "RocGrotesk";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 157.15%;
        }
      }

      .ant-card-body {
        padding: 0;
        border-radius: 0px 0px 20px 20px;
        border: 1px solid #e9e9e9;
      }
    }

    .section-title-wrapper {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      padding: 5px 10px;
      background: #e1e0e0;
      width: 100%;

      .title-text {
        font-family: "RocGrotesk";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 157.15%;
      }

      .value-text {
        font-family: "RocGrotesk";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 157.15%;
      }
    }

    .current-feasible-field {
      margin-bottom: 0 !important;
    }
  }
}

.income-data-visual-container {
  padding: 0;

  .income-target-wrapper {
    padding: 0;
    border-radius: 20px;
    background: #01625f;
    color: #fff;

    .ant-card-body {
      padding: 12px 24px;
    }

    .label {
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .value {
      font-family: "RocGrotesk";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }
}

#understand-income-gap,
#assess-impact-mitigation-strategies,
#closing-gap {
  margin-bottom: 100px;

  .header-wrapper {
    .title {
      color: #01625f;
      font-family: "RocGrotesk";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }

    .description {
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .card-section-wrapper {
    border-radius: 20px;
    background: #01625f;
    color: #fff;
    font-family: "RocGrotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;

    .ant-card-body {
      padding: 10px 20px;
    }
  }

  .card-content-wrapper {
    border-radius: 20px;
  }

  .card-visual-wrapper {
    border-radius: 20px;

    .ant-card-body {
      padding: 12px 14px;
    }

    &.no-padding {
      .ant-card-body {
        padding: 0;
      }
    }

    .section-title {
      color: #01625f;
      font-family: "RocGrotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .section-description {
      // color: #454545;
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      a {
        color: #000;
        font-weight: 600;
      }
    }
  }

  .compare-income-gap-table-wrapper {
    .ant-table {
      border: 1px solid #eaecf0;
      border-radius: 20px;

      .ant-table-thead {
        background: transparent;
        border-radius: 20px;

        // Ensure the top-left and top-right borders apply properly
        tr:first-child th:first-child {
          border-top-left-radius: 20px;
        }

        tr:first-child th:last-child {
          border-top-right-radius: 20px;
        }

        .ant-table-cell {
          color: #fff;
          background: $primary-color;
          font-family: "RocGrotesk";
          font-size: 13px;
          font-weight: 700;
          line-height: 157.15%;

          &::before {
            display: none;
          }
        }
      }

      .ant-table-tbody {
        /* Style for even and odd rows */
        tr:nth-child(even) {
          background-color: rgba(242, 242, 242, 0.5);
        }
        tr:nth-child(odd) {
          background-color: #ffffff;
        }

        // Bottom-left and bottom-right rounding
        tr:last-child td:first-child {
          border-bottom-left-radius: 20px;
        }

        tr:last-child td:last-child {
          border-bottom-right-radius: 20px;
        }

        .ant-table-cell {
          font-family: "TabletGothic";
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}

#assess-impact-mitigation-strategies {
  .carousel-container {
    width: 100%;
    position: relative;

    .carousel-arrows-wrapper {
      position: absolute;
      z-index: 2;
      margin-top: 20%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -12px;

      .button-arrow-carousel {
        border-radius: 22px;
        background: #01625f;
        color: #fff;
      }
    }
  }
  .ant-carousel {
    .slick-dots {
      li {
        width: fit-content;
        button {
          mix-blend-mode: multiply;
          border-radius: 4px;
          background: #818080;
          width: 8px;
          height: 8px;
        }
        &.slick-active {
          button {
            background: #01625f !important;
          }
        }
      }
    }
  }

  .adjust-income-target-wrapper {
    background: #eaf2f2;
    border: none;
    color: #01625f;

    .title {
      font-family: "RocGrotesk";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    .description {
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 157.15%;
    }

    .label {
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .ant-select {
      font-weight: normal;
    }

    .button-ghost-white {
      color: #01625f;
      border-radius: 40px;
      border: 1px solid #01625f;
      background: transparent;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-family: "RocGrotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    .ant-switch {
      background: $muted-color;
    }

    .ant-switch-checked {
      background: $light-green;
    }
  }

  .optimize-income-target-wrapper {
    padding: 10px 0;

    .title {
      font-family: "RocGrotesk";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    .description {
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 157.15%;
    }

    .label {
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .ant-select {
      font-weight: normal;
    }

    .button-ghost-white {
      color: #01625f;
      border-radius: 40px;
      border: 1px solid #01625f;
      background: transparent;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-family: "RocGrotesk";
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    .optimize-income-target-step-wrapper {
      padding: 20px 0;

      .step-wrapper {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--light-40, #d8d8d8);

        .number {
          color: #01625f;
          font-family: "RocGrotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 23.056px;

          background: #ffc505;
          padding: 4px 8px;
          border-radius: 50% 70% 70% 50%;
        }

        .label {
          color: #fff;
          font-family: "TabletGothic";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 157.15%;
        }
      }

      .income-inputs-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        font-weight: normal;

        div {
          flex: 1;

          label {
            display: block;
          }

          .ant-input-number {
            width: 100%;
          }

          span {
            display: block;
          }

          label,
          span {
            color: #fff;
            font-family: "TabletGothic";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            // line-height
          }
        }

        .ant-input-number-group-addon {
          background-color: #eff2f5;
          font-weight: 500;
        }
      }
    }

    .optimize-button-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      .button-clear-optimize-result {
        border-radius: 40px;
        border: 1px solid #fff;
        background-color: transparent;
        color: #fff;
        font-weight: 500;
      }

      .button-run-the-model {
        border-radius: 40px;
        border: 1px solid #ffc505;
        background: #ffc505;
        color: #01625f;
        font-weight: 500;
      }
    }
  }
}

.adjust-income-target-modal-container,
.about-model-modal-container {
  .ant-modal-title {
    font-family: "RocGrotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .ant-modal-body {
    padding: 20px 0;
  }

  .description,
  p,
  li {
    font-family: "TabletGothic";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.32px;
    margin-bottom: 10px;
  }

  .adjust-income-target-step-wrapper {
    padding: 20px 0;

    .step-wrapper {
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid var(--light-40, #d8d8d8);

      .number {
        color: #01625f;
        font-family: "RocGrotesk";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 23.056px;

        background: #ffc505;
        padding: 4px 8px;
        border-radius: 50% 70% 70% 50%;
      }

      .label {
        color: #01625f;
        font-family: "TabletGothic";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 157.15%;
      }
    }

    .input-label {
      color: #4f4f4f;
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 5px 0;
    }

    .current-target-wrapper,
    .adjusted-target-wrapper {
      color: #01625f;
      font-family: "RocGrotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 157.15%;

      border-radius: 14px;
      background: #eaf2f2;
      padding: 5px 20px;
    }
  }
}

#closing-gap {
  .card-section-wrapper {
    .step-wrapper {
      font-size: 16px;
      font-family: "RocGrotesk";
      font-style: normal;
      line-height: 24px;
      font-weight: 700;

      .number {
        padding: 2px 8px;
        background: #ffc505;
        border-radius: 70% 100% 100% 70%;
        color: #01625f;
      }

      .label {
        color: #fff;
      }
    }

    .description {
      margin-top: 5px;
      color: #fff;
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 157.15%;
    }

    .button-add-scenario {
      float: right;
      border-radius: 40px;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &:hover {
        border: 1px solid #48d985;
        color: #48d985;
      }
    }
  }

  .income-driver-form-container {
    .income-driver-form-section {
      .title {
        margin-top: 5px;
        color: $primary-color;
        font-family: "RocGrotesk";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }

      .description {
        font-family: "TabletGothic";
        font-size: 14px;
        line-height: 24px;
      }
    }

    .scenario-field-item {
      margin: 0 !important;
    }
  }

  .step-segment-tabs-container,
  .scenario-segment-tabs-container {
    .ant-tabs-content {
      margin-bottom: 0;
    }
  }

  .complete-button-wrapper {
    border-radius: 20px;
    background: #abefc6;
    border: none;

    .ant-card-body {
      padding: 14px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .text-wrapper {
      flex: 1;

      .title {
        color: #01625f;
        font-family: "RocGrotesk";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }

      .description {
        color: #01625f;
        font-family: "TabletGothic";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .button-wrapper {
      .button-complete {
        border-radius: 40px;
        border: 1px solid #01625f;
        background: #01625f;

        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: #fff;
        font-family: "RocGrotesk";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
}
