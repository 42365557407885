@import "../../../variables.scss";

#tag {
  .tag-form-container {
    .ant-card {
      margin: 16px 0;
    }

    .ant-select {
      width: 100% !important;
    }
  }
}
