@import "../../variables.scss";

#landing,
.landing-container {
  .income-driver-framework-wrapper {
    background-color: #fff;
    width: 100%;
    padding: 0 $default-padding-size 3rem $default-padding-size;

    .income-driver-framework-text-wrapper {
      h2 {
        color: $primary-color;
        font-family: "RocGrotesk";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }

      p {
        color: #000;
        font-family: "TabletGothic";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .info-card-row {
    background: #ffffff;
    padding: 0 3rem 3rem 3rem;
    margin-top: -7rem;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .info-card-wrapper {
      min-height: 200px;
      border-radius: 22px;

      .ant-card-body {
        display: flex;
        padding: 10px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
      }

      .info-card-icon {
        width: 55px;
        height: 55px;
        padding: 6px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        border-radius: 50px 40px 50px 40px;
      }

      h3 {
        color: #004846;
        font-family: "RocGrotesk";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
      }

      p {
        color: #00625f;
        font-family: "TabletGothic";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }

      &.info-first {
        background: #47d985;
      }

      &.info-second {
        background: #76c8ff;
      }

      &.info-third {
        background: #ffc505;
      }
    }
  }

  .other-tools-recources-container {
    padding: 3rem $default-padding-size;
    background: #fff;

    h2 {
      color: $primary-color;
      font-family: "RocGrotesk";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
    }
  }
}
