@import "../../../variables.scss";

#jumbotron {
  background-image: url("/assets/images/jumbotron.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 32rem;
  position: relative;
  overflow: hidden;

  .jumbotron-gradient-wrapper {
    position: relative;
    display: flex;

    .jumbotron-gradient-overlay {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 32rem;
      background: linear-gradient(
        90deg,
        rgba(28, 62, 32, 0.67) 0%,
        rgba(127, 127, 84, 0) 100%
      );
      z-index: 0;
    }
  }

  .jumbotron-text-wrapper {
    padding: 3rem $default-padding-size;
    z-index: 1;
    h1 {
      color: #ffffff;
      font-weight: 900;
      font-size: 48px !important;
      width: 606px;
      line-height: 72px; /* 112.5% */
      margin: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h3 {
      color: #ffffff;
      font-weight: 400;
      font-size: 18px;
      line-height: 157.15%; /* 25.144px */
      width: 567px;
      margin-block-end: 40px;
    }
  }

  .jumbotron-idh-logo {
    position: absolute !important;
    bottom: 0;
  }
}

#framework-drivers {
  background-color: #f9fafb;
  width: 100%;
  padding: 2rem $default-padding-size;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 24px 0 16px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.28px;
    font-family: "TabletGothic";
    border-bottom: 1px solid $muted-color;
  }

  .text-wrapper {
    h2 {
      text-align: center;
      color: $primary-color;
      font-family: "RocGrotesk";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .framework-drivers-calculation-wrapper {
    padding: 0;
    min-height: 245px;
    margin-top: 10px;

    .driver-label {
      color: #000;
      font-family: "RocGrotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .driver-icon-wrapper {
      // border-radius: 100px 0px 100px 100px;
      background: transparent;
      width: 100px;
      height: 100px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &.price-icon {
        background: transparent;
      }
    }

    .math-symbol {
      color: #45d884;
      font-family: "TabletGothic";
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .driver-calculation-stroke {
      margin-top: 16px;
      margin-bottom: 10px;
    }
  }
}

#get-started {
  padding: 3rem $default-padding-size;
  background: #fff;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: $primary-color;
    font-family: "RocGrotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.8px;
  }

  .get-started-info-wrapper {
    .number {
      color: #00625f;
      text-align: center;
      font-family: "RocGrotesk";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.28px;

      padding: 4px 8px;
      background: $yellow-color;
      border-radius: 100px 70px 100px 70px;
    }

    .title {
      color: #00625f;
      font-family: "RocGrotesk";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.336px;
    }

    .description {
      color: #000;
      font-family: "TabletGothic";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding-left: 36px;
    }
  }

  .button-wrapper {
    padding-left: 36px;
    margin-top: 48px;
  }

  .image-wrapper {
    flex: 1 1/2;
    text-align: left;
    img {
      border-radius: 10px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .case-step-wrapper {
    flex: 1/2 1/2;
    .ant-steps-item {
      padding: 0 0 24px 0;
      // not active
      .ant-steps-item-tail::after {
        border: 1px solid #e1e0da;
      }

      .ant-steps-item-icon {
        border: 1px solid #e3e3e3;

        .ant-steps-icon {
          color: #979797;
          text-align: center;
          font-family: "RocGrotesk";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
        }
      }

      .ant-steps-item-title {
        font-family: "RocGrotesk";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding: 0;
        color: #000;
      }
      .ant-steps-item-description {
        color: #475467;
        font-family: "TabletGothic";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin-top: 5px;
      }

      // when active
      &.ant-steps-item-active {
        .ant-steps-item-tail::after {
          border: 1px solid $primary-color;
        }

        .ant-steps-item-icon {
          border: 1px solid $primary-color;
          box-shadow: 0 0 2px 4px rgba(203, 244, 220, 1);
          background: #eaf2f2;

          .ant-steps-icon {
            color: $primary-color;
          }
        }
      }

      // when finished
      &.ant-steps-item-finish {
        .ant-steps-item-icon {
          border: 1px solid #48d985;
          background: #48d985;

          .ant-steps-icon {
            color: #fff;
          }
        }
      }
    }
  }
}

#explore-studies {
  background: #f9fafb;
  width: 100%;
  padding: 3rem $default-padding-size 3.5rem $default-padding-size;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .text-wrapper {
    width: 55%;
  }

  h2 {
    color: $primary-color;
    font-family: "RocGrotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  p {
    color: #000;
    font-family: "TabletGothic";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .map-container {
    background-image: url("/assets/images/explore-studies.svg") !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 35rem;
    margin-top: 50px;

    &.signed-in {
      background-image: url("/assets/images/signed-in-explore-studies.svg") !important;
    }

    &.public {
      background-image: url("/assets/images/visual_for_landing_page.png") !important;
    }
  }

  .pizza-wrapper {
    display: flex;
    align-items: center;
  }

  .pie-container {
    position: relative;
    width: 425px; /* Match image size */
    height: 425px;
  }

  .pie-image {
    width: 100%;
    height: 100%;
  }

  .slice {
    position: absolute;
    transition: opacity 0.2s;
    width: 0;
    height: 0;
    border-left: 90px solid transparent; /* Left side */
    border-right: 90px solid transparent; /* Right side */
    border-bottom: 200px solid rgba(0, 0, 0, 0); /* Bottom (color of triangle) */
    cursor: pointer;
  }

  .slice-1 {
    top: 5%;
    left: 16%;
    transform: rotate(148deg);
  }
  .slice-2 {
    top: 5%;
    right: 16%;
    transform: rotate(208deg);
  }
  .slice-3 {
    top: 28%;
    right: 3%;
    transform: rotate(268deg);
  }
  .slice-4 {
    bottom: 5%;
    right: 16%;
    transform: rotate(328deg);
  }
  .slice-5 {
    bottom: 5%;
    left: 16%;
    transform: rotate(388deg);
  }
  .slice-6 {
    top: 28%;
    left: 3%;
    transform: rotate(448deg);
  }

  .slice:hover {
    opacity: 0.3;
  }
}

#footer-disclaimer {
  background-color: #00625f;
  color: #ffffff !important;
  width: 100%;
  padding: 1.5rem $default-padding-size;

  h2 {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  p {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px;
  }

  .footer-wrapper {
    padding: 1rem 0;
  }
}

#compare-income-target {
  background: #ffffff;

  h2 {
    color: #00403e;
    font-family: "RocGrotesk";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    // line-height: 52px;
  }

  p {
    color: #005855;
    font-family: "TabletGothic";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .compare-income-target-info-card-wrapper {
    padding: 3rem;

    h3 {
      color: #00302e;
      font-family: "RocGrotesk";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-transform: capitalize;
    }

    p {
      color: #00302e;
      font-family: "TabletGothic";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .compare-income-target-card {
      border-radius: 20px;
      min-height: 235px;

      &.info-first {
        background: #47d985;
      }

      &.info-second {
        background: #76c8ff;
      }
    }
  }
}

.ant-popover-inner {
  .pizza-tooltip-title {
    font-family: "RocGrotesk";
    color: $primary-color;
    font-size: 14px;
    font-weight: 700;
    max-width: 200px;
    border-bottom: 1px solid #eaf2f2;
    line-height: 24px;
  }

  .pizza-tooltip-description {
    padding-top: 10px;
    font-family: "TabletGothic";
    color: $primary-color;
    font-size: 13px !important;
    max-width: 200px;
    font-weight: 500;
    line-height: 22px;
  }
}
