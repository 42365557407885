.ant-badge {
  padding: 2px 8px;
  border-radius: 9999px;
  border: 1px solid #e9eaeb;
  background-color: #fafafa;
  white-space: nowrap;
}
.ant-badge.ant-badge-status .ant-badge-status-text {
  color: #414651;
  text-align: center;
  font-family: "TabletGothic";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 157.15%; /* 18.858px */
}
