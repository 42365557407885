@import "../../../variables.scss";

.practice-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  .practice-sidebar {
    width: 100%;
    max-width: 259px;
    min-height: calc(100vh - 80px);
    padding: 22px;
    border: 1px solid #e1e0da;
    background: #fff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 32px;
      li {
        .scale-label {
          color: #000;
          font-family: "RocGrotesk";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 171.429% */
          margin-bottom: 6px;
        }
        .scale-bar {
          list-style-type: none;
          display: flex;
          flex-direction: row;
          gap: 4px;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .scale-bar-item {
              width: 41px;
              padding: 2px 0;
              border-radius: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: 12px; /* 150% */
            }
            .scale-bar-label,
            .scale-bar-icon {
              display: inline-block;
              min-height: 16px;
              text-align: center;
              font-size: 10px;
            }
            .scale-bar-label {
              padding-top: 4px;
              color: #444;
              font-style: normal;
              font-weight: 400;
              line-height: 12px; /* 150% */
            }
          }
        }
      }
    }
  }
  .practice-content {
    width: 100%;
    padding: 22px;
    .practice-content-header {
      padding: 4px 0;
      margin-bottom: 12px;
      .back-btn {
        padding: 0;
        .back-icon {
          svg {
            margin-top: 4px;
            fill: $primary-color;
            transform: scaleX(-1);
          }
        }
        .back-text {
          color: #8a8a8a;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 166.667% */
        }
      }
    }
    .practice-content-body {
      width: 100%;
      .practice-card {
        width: 100%;
        min-height: 165px;
        display: flex;
        flex-direction: row;
        align-items: self-start;
        align-self: stretch;
        justify-content: space-between;
        padding: 18px;
        gap: 12px;
        border-radius: 20px;
        background: #d0ecff;
        background-image: url("/assets/images/procurement-library/bg-card-procurement.png");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 12px;
        .practice-card-description {
          width: 100%;

          h1 {
            color: $primary-color;
            font-size: 32px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 42px; /* 131.25% */
            margin-block-start: 0;
            margin-block-end: 12px;
          }
          p {
            max-width: 729px;
            margin-block-start: 0;
            margin-block-end: 0;
            color: $primary-color;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 157.15%; /* 25.144px */
          }
        }
        .practice-card-categories {
          width: 50%;
          text-align: right;
          .practice-card-categories-label {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-end;
            gap: 8px;
            margin-bottom: 12px;
          }
        }
      }
      .practice-details-card {
        width: 100%;
        min-height: 590px;
        padding: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background-color: #fff;
        h3 {
          margin-block-end: 6px;
        }
        h4 {
          font-family: "RocGrotesk" !important;
          font-size: 18px;
          margin-block-start: 0;
          margin-block-end: 0;
        }
        h3,
        h4 {
          color: $primary-color;
        }
        .practice-secondary-desc {
          color: #454545;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 157.15%; /* 25.144px */
          margin-bottom: 44px;
          p {
            margin-block-start: 0;
            margin-block-end: 0;
          }
        }
        .practice-content-details {
          .practice-content-text {
            width: 100%;
            overflow: hidden;
            white-space: normal;
            word-break: break-word;
          }
          .ant-tabs-left {
            & > .ant-tabs-content-holder {
              border-left: none;
              padding-left: 24px;
            }
            .ant-tabs-ink-bar {
              background-color: transparent;
            }
            .ant-tabs-nav-list {
              width: 420px;
              .ant-tabs-tab {
                width: 100%;
                display: flex;
                align-items: flex-start;
                align-self: stretch;
                padding: 18px;
                gap: 12px;
                border-radius: 20px;
                border: 1px solid #f2f2f2;
                background: #fff;

                &.ant-tabs-tab-active {
                  background-color: #eaf7ff;
                  border: 1px solid #d0ecff;
                }
                .ant-tabs-tab-btn {
                  width: 100%;
                }
                .tab-icon {
                  padding-top: 4px;
                  color: #01625f;
                  svg {
                    stroke: #01625f;
                  }
                }
                .tab-label {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  color: var(--dark-100, #000);

                  font-family: "RocGrotesk";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 157.15%; /* 25.144px */
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .practice-container
    .practice-content
    .practice-content-body
    .practice-details-card
    .practice-content-details
    .ant-tabs-left
    .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      padding: 9px 18px;
    }
  }
  .practice-container
    .practice-content
    .practice-content-body
    .practice-details-card
    .practice-content-details
    .ant-tabs-left
    > .ant-tabs-content-holder {
    padding-left: 12px;
  }
}
