@import "../../../variables.scss";

.methodology-container {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding: 12px 32px;
  .methodology-header {
    display: flex;
    height: 113px;
    padding: 18px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 20px;
    background: #eed06e;
    background-image: url("/assets/images/procurement-library/bg-card-methodology.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 24px;
    h1 {
      color: $primary-color;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px; /* 123.529% */
      letter-spacing: -0.68px;
    }
  }
  .methodology-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 20px;
    background: #fff;
    .methodology-content-item {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      @media (max-width: 1440px) {
        max-width: 820px;
      }

      h2,
      p {
        margin-block-start: 0;
        margin-block-end: 0;
      }
      h2 {
        color: $primary-color;

        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 131.25% */
      }
      p {
        color: #535862;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      strong {
        color: #181d27;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
      }
      blockquote {
        width: 100%;
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        border-radius: 8px;
        background: #fafafa;
        color: #414651;
        font-family: "TabletGothic";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-end: 0;
        span a {
          color: inherit;
          text-decoration: underline;
        }
      }
      .methodology-content-item-group p {
        margin-block-start: 12px;
        &.first-paragraph {
          margin-block-start: 0;
        }
      }
    }
  }
}

#root {
  h1 {
    font-size: 34px !important;
  }
  h2 {
    font-size: 32px !important;
  }
}
