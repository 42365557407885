@import "../../variables.scss";

.case-filter-container {
  width: 375px !important;

  .case-filter-header {
    .case-filter-title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .case-filter-body {
    margin-top: 16px;
    label {
      font-weight: 500;
      font-size: 12px;
    }
  }

  .case-filter-footer {
    margin-top: 16px;
    .button-filter {
      background-color: $primary-color;
      color: #fff;
      font-weight: 500;
      border: none;

      :hover {
        color: #fff;
      }
    }
    .button-reset {
      font-weight: 500;
    }
  }
}

.case-settings-modal-container {
  .ant-modal-body {
    height: 70vh;
    overflow-y: auto;
    padding: 24px 5px;
  }

  .case-setting-child-card-wrapper {
    .ant-card-head {
      background: #f2f2f2;
    }

    .section-title {
      font-weight: 500;
      line-height: 1.2;
    }
  }

  .segment-card-container {
    margin-bottom: 14px;

    .ant-card-head {
      background: transparent;
    }
  }
}

#case {
  margin-top: 24px;

  h2 {
    font-family: "RocGrotesk" !important;
  }
}
