@import "./variables.scss";

#root {
  // global css setting
  font-family: "TabletGothic" !important;

  h1 {
    font-family: "RocGrotesk" !important;
    font-weight: 700 !important;
    font-size: 48px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h2 {
    font-family: "RocGrotesk" !important;
    font-size: 38px;
  }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 36px;
    }
  }

  h3 {
    font-family: "RocGrotesk" !important;
    font-size: 20px;
  }

  h4,
  h5,
  h6,
  p,
  a,
  .ant-table-cell,
  .ant-table-pagination {
    font-family: "TabletGothic" !important;
  }

  .page-title {
    color: $primary-color !important;
  }
  .page-subtitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 1.5rem;
    font-weight: normal;
  }

  .ant-btn {
    font-size: 13px;
  }

  .button-green {
    border: 2px solid $primary-color;
    border-radius: 40px;
    color: $primary-color;
    font-weight: 700;
  }

  .button-green-transparent {
    border: 2px solid $primary-color;
    border-radius: 40px;
    color: $primary-color;
    font-weight: 700;
    background: transparent;
  }

  .button-green-fill {
    background: $primary-color;
    border-radius: 40px;
    color: #ffffff !important;
    font-weight: 700;
    border: 2px solid $primary-color;
  }
  .button-yellow-fill {
    background-color: $yellow-color;
    border-radius: 40px;
    color: $primary-color;
    font-weight: 700;
    border: 2px solid $yellow-color;
  }

  .button-ghost {
    background: transparent;
    border-radius: 40px;
    border-color: $primary-color;
    font-weight: 700;
    color: $primary-color;
  }

  .button {
    border-radius: 40px;
    font-weight: 600;
    color: $primary-color;
    padding: 12px 18px;
    min-height: 48px;
  }
  .button-yellow {
    background-color: $yellow-color;
    color: #000;
  }
  .button-secondary {
    border: 2px solid $primary-color;
  }
  .button-submit {
    margin: 1rem 0px;
  }

  .font-tablet-gothic {
    font-family: "TabletGothic" !important;
  }
  .font-roc-grotesk {
    font-family: "TabletGothic" !important;
  }

  .button-light {
    font-weight: normal;
  }

  .font-tablet-gothic {
    font-family: "TabletGothic" !important;
  }
  .font-roc-grotesk {
    font-family: "TabletGothic" !important;
  }
  // EOL global css setting

  header {
    height: 5rem;
    background: $bg-color-white;
    padding: 0.5rem $default-padding-size;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.08);
    z-index: 999 !important;

    .title {
      margin-left: $default-padding-size;
      h3 {
        color: #ffffff;
      }
    }

    .navigation-container {
      margin-left: $default-padding-size;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      &.ant-menu-horizontal {
        border: none;

        li {
          font-family: "RocGrotesk";
          font-size: 14px;
          color: $primary-color;
          font-weight: 700;
        }
      }

      .ant-menu-item::after,
      .ant-menu-submenu::after {
        // remove bottom border for the menu active
        border: none;
      }

      .anticon {
        font-size: 10px;
      }

      a {
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
      }
      .nav-sign-in {
        background-color: $primary-color;
        color: #ffffff;
        padding: 10px 14px;
        border-radius: 40px;
        font-size: 14px !important;
        font-weight: 600;
      }
    }
  }

  .ant-breadcrumb {
    padding: 0 $default-breadcrumb-padding-size;
  }

  .table-content-container {
    .search-and-add {
      margin: 1rem 0px;
      background: $bg-color-grey;

      .ant-card-body {
        padding: 10px 0 20px 0;
      }

      .search {
        width: 30vw;
        button {
          // border-color: $bg-color-grey;
          // background-color: $bg-color-grey;
          color: #000;
        }
      }
    }

    .table-content-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 8px;

      .ant-table-title {
        padding: 0 16px;
        background: $primary-color;
        color: #fff;

        h4 {
          font-weight: 700;
        }

        label {
          color: #fff;
        }

        .ant-select-selection-placeholder {
          font-weight: 500;
          color: #000;
        }
      }

      .ant-table-pagination {
        background: #fff;
        margin: 0;
        padding: 10px 15px;
        border-radius: 0 0 8px 8px;
      }
    }
  }

  aside {
    ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.css-dev-only-do-not-override-relbw9 {
      padding-top: 12px;
    }

    li.ant-menu-item.ant-menu-item-selected {
      background: #e6f7ff;
      font-weight: 600;
      border-right: 4px solid $primary-color;
    }
  }

  .content-container {
    min-height: calc(100vh - 80px);
    background: $bg-color-grey;

    .content-card-container {
      background-color: $bg-color-grey;

      .content-card-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0px 2rem;
      }

      .ant-card-body {
        padding: 20px 0 16px 0;

        .ant-breadcrumb-link {
          font-family: "TabletGothic" !important;
          font-size: 0.95rem;
          font-weight: 600;
          color: #8c8c8c;
        }

        .title-wrapper {
          padding: 0 $default-padding-size;
          padding-top: 14px;
        }

        .title {
          font-family: "TabletGothicBold";
          font-size: 1.5rem;
          font-weight: 600;
          padding-top: 4px;
          color: $primary-color;
        }

        .subTitle {
          padding: 0 $default-padding-size;
        }
      }
    }

    .content-wrapper {
      padding: 0 $default-padding-size 3rem $default-padding-size;

      .ant-space {
        width: 100%;
      }

      .ant-select {
        width: 30vw;
      }
    }

    .admin-tab-menu-container {
      padding: 0 $default-padding-size;
      background: $bg-color-grey;
      .ant-tabs-nav {
        margin: 0;
      }
      .ant-tabs-nav-wrap {
        background: $bg-color-grey;
        // padding: 10px 1.5rem 0px;
        .ant-tabs-tab-btn {
          font-family: "RocGrotesk";
          font-size: 1rem;
          font-weight: 700;
          background: $bg-color-grey;
        }
      }
    }
  }

  .ant-card {
    box-shadow: none;
    border: none;
    font-family: "TabletGothic" !important;
    .ant-card-title {
      font-family: "TabletGothicBold";
      font-size: 1.5rem;
      font-weight: 600;
    }
    .ant-card-body {
      font-family: "RocGrotesk" !important;
    }
  }

  .loading-container {
    padding: 8rem;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
  }

  .ant-timeline {
    font-family: "RocGrotesk";
    font-weight: bold;
    .ant-timeline-item {
      color: $muted-color;
    }
    .ant-timeline-item-content {
      inset-block-start: -3px;
    }
    .ant-timeline-item-head {
      border-color: $muted-color;
      background-color: transparent;
      width: 15px;
      height: 15px;
    }
    .ant-timeline-item-tail {
      inset-inline-start: 7.5px;
    }
  }

  .ant-form {
    input {
      font-family: "TabletGothic";
    }
    .ant-form-item {
      .ant-form-item-explain-error {
        font-family: "TabletGothic";
      }
    }
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item,
  .ant-form-item-label {
    font-family: "TabletGothic";
    text-align: left;
  }
  .chart-container {
    padding: 24px;
  }

  .card-alert-box {
    padding-bottom: 20px;
    margin-bottom: 1.5rem;
    border-radius: 20px;
    background: #d0e2e2;

    .title {
      color: #01625f;
      font-family: "RocGrotesk";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    .description {
      color: #01625f;
      font-family: "TabletGothic";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .password-criteria-wrapper {
    margin: 0 0 20px 0;
    p,
    .ant-checkbox-group-item {
      font-family: "TabletGothic" !important;
      font-size: 14px;
    }

    &.white,
    .white {
      p,
      .ant-checkbox-group-item {
        color: #fff !important;
      }
    }
  }

  .breadcrumb-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .breadcrumb-left-content {
      flex: 1;
    }

    .breadcrumb-right-content {
      font-size: 13px;
      font-family: "TabletGothic" !important;
      font-style: italic;
    }
  }

  .other-tool-resource-list-container {
    margin-top: 24px;

    .other-tool-resource-list {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(250px, 1fr)
      ); /* Responsive grid */
      gap: 20px; /* Adjust spacing between items */

      .otr-item-wrapper {
        display: flex;
        flex-direction: column;

        h2 {
          color: #000 !important;
          text-align: left !important;
          font-family: "RocGrotesk";
          font-size: 16px !important;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        p {
          margin: 0;
          text-align: left;
          font-family: "TabletGothic";
          font-size: 14px;
          line-height: 24px;
        }

        .otr-tag-button-wrapper {
          width: 100%;
        }
      }
    }

    @media (min-width: 1024px) {
      .other-tool-resource-list {
        grid-template-columns: repeat(
          3,
          1fr
        ); /* 3 columns for medium screens */
      }
    }

    @media (min-width: 1440px) {
      .other-tool-resource-list {
        grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
      }
    }

    .button-wrapper {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        border-radius: 100px;
        border: 1px solid $primary-color;
        background: $primary-color;
        color: #fff;
        font-family: "RocGrotesk";
        font-weight: 700;
      }
    }
  }

  .page-title-layout-container {
    .page-title-layout-header-wrapper {
      background: #d5ebfd;
      position: relative;
      overflow: hidden;

      .title-wrapper {
        padding: 2rem $default-padding-size;

        h1 {
          margin: 0;
          color: $primary-color;
          font-weight: 700;
          font-size: 32px !important;
        }

        p {
          margin: 5px 0;
          font-size: 16px;
        }
      }
    }
  }

  .page-footer-container {
    color: #fff;

    &.with-padding-bg {
      padding: 1rem $default-padding-size;
      background-color: #00625f;
    }

    &.with-fixed-position {
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    .copyright-text {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}

.ant-popover {
  .ant-popover-inner-content {
    .fn-info {
      font-family: "TabletGothic" !important;
      font-size: 12px;
      max-width: 60%;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-cell {
    color: #000;
  }
  .ant-picker-cell-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-tooltip-inner {
  background-color: #1b625f !important;
  font-family: "TabletGothic" !important;
  font-size: 12px;
  color: #fff;

  strong {
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
  }
}

.visual-card-wrapper {
  padding: 0;
  border-radius: 20px;

  .ant-card-head {
    padding: 10px 14px;
    background: #dfdfdf;
    border-radius: 20px 20px 0px 0px;

    .title {
      font-family: "RocGrotesk";
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 157.15%;
      color: #000 !important;
    }

    .button-export {
      margin-left: 5px;
      border: 1px solid #01625f;
      font-weight: 500;
      font-size: 12px !important;
      background: transparent;
      color: #01625f;
      border-radius: 40px;
      padding: 2px 10px;

      &:hover {
        border: 2px solid #01625f;
      }
    }
  }

  .ant-card-body {
    padding: 12px 14px;
    border-radius: 0px 0px 20px 20px;
    background: #fff;
  }

  &.bordered {
    .ant-card-head {
      border: 1px solid #dfdfdf;
    }
    .ant-card-body {
      border: 1px solid #dfdfdf;
      border-top: none;
    }
  }
}

.leaflet-interactive:focus {
  outline: none;
}

.leaflet-container {
  border-radius: 20px;
  z-index: 1;
}

// general modal button border-radius
.ant-modal {
  .ant-btn {
    border-radius: 24px;
  }
}

// dropdown menu
.ant-menu-submenu-placement-bottomLeft {
  padding: 0;
}
.ant-menu-submenu-popup::before {
  content: none;
  display: none;
}
.ant-menu-sub {
  margin-top: -12px !important;
  border-radius: 0 0 5px 0 !important;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  min-width: 200px !important;
}
// EOL dropdown menu
