@import "../../variables.scss";

#faq-page {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .ant-collapse {
      border: none;
      background: transparent;

      .ant-collapse-item {
        border-radius: 20px;
        border: 1px solid #f2f2f2;
        background: #fff;
        padding: 12px;

        .ant-collapse-header {
          color: $primary-color;
          font-family: "RocGrotesk";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          border: none;
        }

        .ant-collapse-content {
          color: $primary-color;
          font-family: "TabletGothic";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.32px;
          border-top: 1px solid rgba(1, 98, 95, 0.2);
        }
      }
    }
  }
}
