@import "../../variables.scss";

#welcome {
  padding: 24px 0;

  .welcome-with-padding {
    padding: 0 $default-padding-size;
  }

  .username-wrapper {
    color: #01625f;
    font-family: "RocGrotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 157.15%;
    padding-bottom: 10px;
  }

  .jumbotron-card-wrapper {
    .welcome-card-wrapper {
      padding: 0 24px 84px 24px;
      color: #fff;
      border-radius: 20px;
      background: #01625f;

      .welcome-title {
        font-family: "RocGrotesk";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }

      .welcome-subtitle {
        font-family: "TabletGothic";
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
      }
    }
  }

  .floating-card-wrapper {
    margin-top: -92px;

    .floating-card-item {
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      height: 100%; // Ensures all cards have equal height

      .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%; // Ensures consistent card height
        padding: 20px;
        min-height: 10rem;
        @media (max-width: 1280px) {
          min-height: 13rem;
        }
      }

      .title {
        color: #01625f;
        line-height: 28px;
        @media (max-width: 1280px) {
          min-height: 48px;
        }
        h3 {
          margin: 0;
        }
      }

      .description {
        color: #444;
        font-family: "TabletGothic";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        flex: 1; // Makes description fill space
        display: flex;
        align-items: center;
        @media (max-width: 1280px) {
          min-height: 60px;
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: flex-end; // Aligns the button to the bottom
      }

      .button-explore {
        border-radius: 40px;
        border: #01625f;
        background: #01625f;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #fff;
        font-family: "RocGrotesk";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
      }
    }

    .case-card-wrapper {
      background: #eed16e;
    }

    .explore-studies-card-wrapper {
      background: #7dd3ab;
    }

    .lib-card-wrapper {
      background: #9cd7ff;
    }
  }

  // Responsive Fixes
  @media (max-width: 1024px) {
    .floating-card-wrapper .ant-row {
      flex-direction: column;
      align-items: center;
    }
    .floating-card-wrapper .ant-col {
      width: 100%;
    }
  }

  .map-card-wrapper {
    border-radius: 20px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 1px 4px 0px rgba(16, 24, 40, 0.25);

    .ant-card-body {
      padding: 0 20px 20px 0;

      .leaflet-container {
        border-radius: 20px;
      }
    }
  }

  .map-container {
    margin-top: 32px;
    margin-bottom: -$default-padding-size;
    padding: 32px $default-padding-size;
    background: #dedede;
    width: 100%;

    .map-heading-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        color: #01625f;
        font-family: "RocGrotesk";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .description {
        color: #000;
        font-family: "TabletGothic";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 157.15%;
      }
    }
  }

  .table-container {
    padding-top: 24px;
  }
}

.view-summary-modal-wrapper {
  .scenario-outcome-form-wrapper {
    border-radius: 20px 20px 0px 0px;
    background: #f2f2f2;

    .ant-card-body {
      padding: 16px 24px;
    }

    label {
      font-family: "RocGrotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 157.15%;
    }

    .button-download {
      background: transparent;
      border-radius: 40px;
      border: 1px solid #01625f;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #01625f;
      font-family: "RocGrotesk";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 142.857%;
    }
  }
}
