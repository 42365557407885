body,
html {
  height: 100%;
  margin: 0;
}

body {
  font-family: "TabletGothic", -apple-system, BlinkMacSystemFont, "Roboto",
    "Ubuntu", "Fira Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important; // default font size to rem
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
