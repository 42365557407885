@import "../../variables.scss";

#cii-dashboard-page {
  min-height: calc(100vh - 10px);

  .cii-dashboard-page-title-wrapper {
    padding: 24px $default-padding-size 34px $default-padding-size;
    background-color: #fffaeb;

    h1 {
      margin: 10px 0;
      color: $primary-color;
      font-family: "RocGrotesk";
      font-size: 32px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      text-transform: capitalize;
    }

    p {
      margin: 5px 0;
      color: $primary-color;
      font-size: 16px;
    }

    .btn-wrapper {
      margin-top: 20px;
      display: flex;
      gap: 10px;
    }
  }

  .cii-dashboard-power-bi-iframe-wrapper {
    padding: 24px $default-padding-size;
    background-color: #f7f7f5;
    min-height: 99vh;
    position: relative;
  }
}
